import React from 'react';
import Helmet from 'react-helmet';

// Config
import config from '../../../../config/website';

const SeoDetails = props => {
  const { seoContent } = props;
  const { pageTitle, slug, description, featuredImage } = seoContent;

  const pageUrl = `${config.siteUrl}/${slug}/`;
  const pageImage = config.siteUrl + featuredImage.fluid.src;

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: pageUrl,
      name: `${pageTitle} - ${config.siteTitle}`
    }
  ];

  schemaOrgJSONLD.push({
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: pageUrl,
    name: `${pageTitle} - ${config.siteTitle}`
  });

  return (
    <Helmet>
      {/* General tags */}
      <title>
        {pageTitle} | {config.siteTitle}
      </title>
      <meta name="image" content={pageImage} />
      <meta name="description" content={description.description} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={pageImage} />
      <meta property="og:description" content={description.description} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.twitterHandle} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:image" content={pageImage} />
      <meta name="twitter:description" content={description.description} />
    </Helmet>
  );
};

export default SeoDetails;
